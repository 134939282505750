<script>
import FullCalendar from '@fullcalendar/vue3'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { patch, post, destroy } from '@rails/request.js';

export default {
  components: {
    FullCalendar
  },
  props: {
    attendancesUrl: String,
    eventStartDate: String,
    eventEndDate: String,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [ interactionPlugin, bootstrap5Plugin, timeGridPlugin ],
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        initialView: 'timeGridWeekCustom',
        views: {
          timeGridWeekCustom: {
            type: 'timeGrid',
            duration: { days: 4 }
          }
        },
        themeSystem: 'bootstrap5',
        height: "auto",
        nowIndicator: true,
        locale: 'de',
        slotDuration: '00:30:00',
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
        },
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
        },
        headerToolbar: {
          left:   '',
          right:  'prev,next'
        },
        visibleRange: {
          start: this.eventStartDate,
          end: this.eventEndDate
        },
        validRange: {
          start: this.eventStartDate,
          end: this.eventEndDate
        },
        events: [{
          start: this.eventStartDate,
          end: this.eventEndDate,
          editable: false,
          display: 'inverse-background',
          backgroundColor: 'grey'
        }],
        eventSources: [
          {
            id: "attendances",
            url: this.attendancesUrl,
          }
        ],
        eventClick: this.onClick,
        editable: true,
        eventDrop: this.onDropOrResize,
        eventResize: this.onDropOrResize,
        dragRevertDuration: 0,
        selectable: true,
        select: this.onSelection,
      }
    }
  },
  methods: {
    onClick: async function(eventClickInfo) {
      eventClickInfo.jsEvent.preventDefault();
    },
    onDropOrResize: async function(eventResizeOrDropInfo) {
      var attendance = eventResizeOrDropInfo.event;

      if (typeof attendance.id == 'undefined') {
        eventResizeOrDropInfo.revert();
        return;
      }

      const response = await patch(attendance.url, { body: JSON.stringify({
        attendance: {
          starts_at: attendance.startStr,
          ends_at: attendance.endStr
        }
      })})

      if (response.ok) {
        this.$refs.calendar.getApi().refetchEvents()
      } else {
        eventResizeOrDropInfo.revert();
      }
    },
    onSelection: async function(selectionInfo) {
      if (selectionInfo.end - selectionInfo.start < 3600000) {
        // Check against a minimal duration of 60 minutes to skip random clicks directly on the calendar
        return
      }

      const response = await post(this.attendancesUrl, { body: JSON.stringify({
        attendance: {
          starts_at: selectionInfo.start.toISOString(),
          ends_at: selectionInfo.end.toISOString()
        }
      })})

      if (response.ok) {
        this.$refs.calendar.getApi().refetchEvents()
      }
    },
    delete_attendance: async function(url) {
      const response = await destroy(url)
      if (response.ok) {
        this.$refs.calendar.getApi().refetchEvents()
      }
    }
  }
}
</script>
<template>
  <FullCalendar :options="calendarOptions" ref="calendar">
    <template v-slot:eventContent='arg'>
      <span class="bi bi-trash" @click="delete_attendance(arg.event.url)"> Delete</span>
    </template>
  </FullCalendar>
</template>
