<script>
import FullCalendar from '@fullcalendar/vue3'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { put, get } from '@rails/request.js'
import TimetableMixin from '../mixins/timetable.vue'

export default {
  components: {
    FullCalendar
  },
  mixins: [TimetableMixin],
  props: {
    volunteerToken: String,
    positionsUrl: String,
    shiftsUrl: String,
    shiftVolunteerUrl: String,
    eventStartDate: String,
    eventEndDate: String,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [ interactionPlugin, bootstrap5Plugin, resourceTimelinePlugin ],
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        initialView: 'resourceTimeline',
        themeSystem: 'bootstrap5',
        height: "auto",
        nowIndicator: true,
        locale: 'de',
        slotDuration: '00:15:00',
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
        },
        slotLabelFormat: {
          hour: 'numeric',
          minute: '2-digit',
          omitZeroMinute: false,
        },
        headerToolbar: {
          left:   'title',
          right:  'switchSlotDuration prev,next'
        },
        customButtons: {
          switchSlotDuration: {
            text: '15m/30m',
            click: this.toggleSlotDuration
          }
        },
        validRange: {
          start: this.eventStartDate,
          end: this.eventEndDate
        },
        events: [{
          start: this.eventStartDate,
          end: this.eventEndDate,
          editable: false,
          display: 'inverse-background',
          backgroundColor: 'grey'
        }],
        eventSources: [
          {
            id: "shifts",
            url: this.shiftsUrl,
          },
        ],
        eventDrop: this.onDropOrResize,
        eventResize: this.onDropOrResize,
        dragRevertDuration: 0,
        selectable: true,
        select: this.onSelection,
        eventClick: this.onClick,
        datesSet: this.onDateChanged,
        resources: this.positionsUrl,
        resourceGroupField: 'divisionName',
        resourceOrder: ['divisionName', 'title'],
        resourceAreaColumns: [
          {
            field: 'title',
            headerContent: this.$i18n.t("timetable.positions.headers.position"),
          },
        ]
      }
    }
  },
  channels: {
    EventsChannel: {
      received(data) {
        switch (data) {
          case "shifts_changed":
            this.$refs.calendar.getApi().refetchEvents()
            break;
          case "positions_changed":
            this.$refs.calendar.getApi().refetchResources()
            break;
        }
      }
    },
  },
  mounted() {
    this.$cable.subscribe({
      channel: "EventsChannel",
      volunteerToken: this.volunteerToken,
    });
  },
  methods: {
    onDropOrResize: async function(eventResizeOrDropInfo) {
      var shift = eventResizeOrDropInfo.event;

      if (typeof shift.id == 'undefined') {
        eventResizeOrDropInfo.revert();
        return;
      }

      // The upper limit of the duration must not be inclusive to not (mathematically) overlap with following shifts
      // As Fullcalendar does an inclusive selection, substract one second manually
      shift.end.setSeconds(shift.end.getSeconds() - 1)

      const response = await put(shift.url, { body: JSON.stringify({
        shift: {
          starts_at: shift.start.toISOString(),
          ends_at: shift.end.toISOString(),
          position_id: shift.getResources()[0].id
        }
      })})
      if (!response.ok) {
        eventResizeOrDropInfo.revert();
      }
    },
    onSelection: async function(selectionInfo) {
      if (selectionInfo.end - selectionInfo.start < 1800000) {
        // Check against a minimal duration of 30 minutes to skip random clicks directly on the calendar (e.g. to close modal dialogs)
        return
      }

      // The upper limit of the duration must not be inclusive to not (mathematically) overlap with following shifts
      // As Fullcalendar does an inclusive selection, substract one second manually
      selectionInfo.end.setSeconds(selectionInfo.end.getSeconds() - 1)

      const response = await get(this.shiftVolunteerUrl, { query: {
        "shift[starts_at]": selectionInfo.start.toISOString(),
        "shift[ends_at]": selectionInfo.end.toISOString(),
        "shift[position_id]": selectionInfo.resource.id,
      }})
      if (!response.ok) {
        return
      }

      const content = await response.text
      $("#volunteer-selection").html(content)

      bootstrap.Modal.getOrCreateInstance("#volunteer-modal").show()
    }
  }
}
</script>
<template>
  <FullCalendar :options="calendarOptions" ref="calendar">
    <template v-slot:resourceGroupLabelContent='arg'>
      {{ arg.groupValue || this.$i18n.t("timetable.volunteers.headers.noGroup") }}
    </template>

    <template v-slot:resourceLabelContent='arg'>
      <span class="bi-icon bi-circle-fill me-1" :style="{color: arg.resource.extendedProps.color}"></span>
      <div style="display: table-cell">
        <span>
          <a :href="arg.resource.extendedProps.url">{{ arg.resource.title }}</a>
          <span v-if="arg.resource.extendedProps.comment" class="small"><br />{{ arg.resource.extendedProps.comment }}</span>
        </span>
      </div>
    </template>

    <template v-slot:eventContent='arg'>
      <VueTimetableShift :event="arg.event" />
    </template>
  </FullCalendar>
</template>
